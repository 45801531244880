import React from 'react';

const QuizIndex = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <iframe
        src="https://childrenscenturyclassics.com/quizzes/37-gods-perfect-playground/#app"
        style={{ width: '100%', height: '100%' }}
        marginHeight="0"
        marginWidth="0"
      />
    </div>
  );
};

export default QuizIndex;
